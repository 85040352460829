import React, { useState, useEffect, useContext, Fragment } from "react";
import matchSorter from "match-sorter";
import Header from "../components/Header";
import { jsx } from "@emotion/core";
import { groupBy, keys } from "lodash";
import useTranslations from "../components/hooks/useTranslations";
import { LocaleContext } from "../context/locale-context";
import { rhythm, scale } from "../utils/typography";
import data from "../../data/data.json";
import linksData from "../../data/links.json";
import useWindowSize from "../components/hooks/useWindowSize";
import ReactTable from "react-table";
import "react-table/react-table.css";
import SEO from "../components/seo";
const onlineLinks = groupBy(linksData, value => value.id);

const Incident = ({ data }) => {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  return (
    <div style={{ padding: "20px" }}>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          gridColumnGap: rhythm(2)
        }}
      >
        <dl>
          <dt>{tr("Title")}</dt>
          <dd>{isRTL ? data.original.title_ar : data.original.title_en}</dd>
          <dt>{tr("Summary of incident")}</dt>
          <dd>{isRTL ? data.original.summary_ar : data.original.summary_en}</dd>
        </dl>
        <dl>
          <dt>{tr("Alleged Perpetrator")}</dt>
          <dd>{data.original.alleged_perpetrator}</dd>
          <dt>{tr("Weapons")}</dt>
          <dd>{data.original.weapons}</dd>
        </dl>
        <dl>
          <dt>{tr("Online Links")}</dt>
          <dd>
            <ul
              css={{
                listStyle: "none"
              }}
            >
              {onlineLinks[data.original.id].map((v, i) => (
                <li
                  key={i}
                  css={{
                    display: "inline",
                    padding: rhythm(0.5)
                  }}
                >
                  <a
                    href={v.link}
                    css={{
                      fontWeight: "800",
                      color: "#60323D",
                      textDecorationLine: "underline",
                      textDecorationStyle: "double"
                    }}
                  >
                    {v.platform}
                  </a>
                </li>
              ))}
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>{tr("Incident Code")}</dt>
          <dd>{data.original.id}</dd>
        </dl>
      </div>
    </div>
  );
};

function DataSet() {
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  }, [windowWidthSize]);
  return (
    <Fragment>
      <SEO />
      <Header />
      <div
        css={{
          maxWidth: isMobile ? "95%" : "75%",
          margin: isMobile ? "auto" : "4rem auto",
          direction: isRTL ? "rtl" : "ltr",
          textAlign: isRTL ? "right" : "left"
        }}
      >
        <h3>{tr("Discover Data")}</h3>
        {isMobile || (
          <ReactTable
            data={data}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                columns: [
                  {
                    Header: `${tr("Incident Date")}`,
                    accessor: "incident_date",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [`incident_date`]
                      }),
                    filterAll: true,
                    maxWidth: 150
                  },
                  {
                    Header: `${tr("Title")}`,
                    accessor: `title_${locale}`,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [`title_${locale}`]
                      }),
                    filterAll: true
                  },
                  {
                    Header: `${tr("Alleged Perpetrator")}`,
                    accessor: "alleged_perpetrator",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [`alleged_perpetrator`]
                      }),
                    filterAll: true,
                    maxWidth: 200
                  },
                  {
                    Header: `${tr("Location")}`,
                    accessor: "location",
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [`location`]
                      }),
                    filterAll: true
                  }
                ]
              }
            ]}
            defaultPageSize={20}
            className="-striped -highlight"
            SubComponent={row => {
              return <Incident data={row} />;
            }}
          />
        )}
        {isMobile && (
          <ReactTable
            data={data}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                columns: [
                  {
                    Header: `${tr("Title")}`,
                    accessor: `title_${locale}`,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: [`title_${locale}`]
                      }),
                    filterAll: true
                  }
                ]
              }
            ]}
            defaultPageSize={20}
            className="-striped -highlight"
            SubComponent={row => {
              return <Incident data={row} />;
            }}
          />
        )}
      </div>
    </Fragment>
  );
}

export default DataSet;
